<template>
  <div class="mb_download">
    <div class="mb_md_1 mb_box">
      <div class="mb_cnt">
        <div class="mb_md_1_left">
          <img src="../assets/image/mb_img_1_1.png" alt="" />
          <img src="../assets/image/mb_img_1_3.png" alt="" />
          <p>芜湖教育</p>
          <p>多场景 · 多终端 · 多角色的统一入口</p>
          <div class="btn_box">
            <button @click="showQr('Android')">
              <img src="../assets/image/mb_img_1_4.png" alt="" />Android下载
            </button>
            <button @click="showQr('IOS')">
              <img src="../assets/image/mb_img_1_5.png" alt="" />IOS下载
            </button>
          </div>
        </div>
        <div class="mb_md_1_right">
          <img src="../assets/image/mb_img_1_2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mb_md_2 mb_box">
      <div class="mb_cnt">
        <div class="mb_md_2_left">
          <p>场景融合，应用丰富</p>
          <p>教、学、考、评、管全场景串联，业务更流畅</p>
          <p>局、校、家三位一体，多角色切换更实用</p>
        </div>
        <div class="mb_md_2_right">
          <img src="../assets/image/mb_img_2_1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mb_md_3 mb_box">
      <div class="mb_cnt">
        <div class="mb_md_3_left">
          <img src="../assets/image/mb_img_3_1.png" alt="" />
        </div>
        <div class="mb_md_3_right">
          <!-- <img src="../assets/image/mb_img_2_1.png" alt="" /> -->
          <p>简单易用，体验一致</p>
          <p>不同角色差异化设计，个性设置更灵活</p>
          <p>统一设计规范，产品体验更一致</p>
        </div>
      </div>
    </div>
    <div class="mb_md_4 mb_box">
      <div class="mb_cnt">
        <div class="mb_md_4_left">
          <p>多端互通，全平台使用</p>
          <p>统一待办消息，信息推送更精准</p>
          <p>多终端数据互通，产品操作更流畅</p>
        </div>
        <div class="mb_md_4_right">
          <img src="../assets/image/mb_img_4_1.png" alt="" />
          <img src="../assets/image/mb_img_4_2.png" alt="" />
          <button>在校表现</button>
          <button>家校沟通</button>
          <button>班牌沟通</button>
        </div>
      </div>
    </div>
    <div class="mb_md_5 mb_box">
      <div class="mb_cnt">
        <div class="mb_md_5_left">
          <img src="../assets/image/mb_img_5_1.png" alt="" />
        </div>
        <div class="mb_md_3_right">
          <!-- <img src="../assets/image/mb_img_2_1.png" alt="" /> -->
          <p>安全可靠，灵活可控</p>
          <p>基础数据源统一对接、真实可靠</p>
          <p>沟通权限可管控、内容安全可监管</p>
        </div>
        <div class="qr_btn" @click="showQr('all')">
          <img src="../assets/image/btn_qr.png" alt="" />
          <p>扫码下载</p>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="diaQr" :width="diaWidth" :append-to-body="true">
      <div class="dia_cnt">
        <div v-if="showType === 'Android' || showType === 'all'">
          <img src="../assets/image/qr_code.png" alt="" />
          <p>扫描二维码下载</p>
        </div>
        <div v-if="showType === 'IOS' || showType === 'all'">
          <img src="../assets/image/qr_code_ios.png" alt="" />
          <p>扫描二维码下载</p>
        </div>
      </div>
    </el-dialog>
    <link_footer></link_footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import link_footer from "./home_com/footer.vue";
export default {
  name: "mb_download",
  props: {},
  computed: mapState({
    // userId: (state) => state.user.userId,
  }),
  components: {
    link_footer,
  },
  data() {
    return {
      diaQr: false,
      diaWidth: "270px",
      showType: "Android",
    };
  },
  mounted() {},
  methods: {
    showQr(type) {
      this.diaQr = true;
      this.showType = type;
      this.showType === "all" ? (this.diaWidth = "450px") : (this.diaWidth = "270px");
    },
  },
};
</script>

<style scoped lang="scss">
.mb_box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mb_cnt {
  width: 1200px;
  display: flex;
}
.mb_download {
  font-size: 14px;
}
.mb_md_1 {
  width: 100%;
  background: linear-gradient(180deg, #e6f5ff 0%, #ffffff 100%);
  display: flex;
}
.mb_md_1_left {
  img {
    display: block;
  }
  img:nth-of-type(1) {
    width: 384px;
    height: 157px;
  }
  img:nth-of-type(2) {
    width: 89px;
    height: 68px;
    padding: 3px 0 0 0px;
  }
  p:nth-of-type(1) {
    padding: 16px 0 0 0px;
    font-size: 46px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 65px;
  }
  p:nth-of-type(2) {
    padding: 12px 0 0 0px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
    line-height: 28px;
  }
  .btn_box {
    padding: 40px 0 0 0px;
    button {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      padding: 20px 0px;
      width: 168px;
      background: #368fff;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.03);
      border-radius: 43px;
      border: none;
      margin-right: 16px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 8px;
      }
    }
  }
}
.mb_md_1_right {
  img {
    width: 976px;
    height: 600px;
  }
}
.mb_md_2 {
  .mb_md_2_left {
    p:nth-of-type(1) {
      font-size: 42px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 59px;
      padding-top: 218px;
      padding-bottom: 26px;
    }
    p:nth-of-type(2),
    p:nth-of-type(3) {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 32px;
    }
  }
  .mb_md_2_right {
    padding: 64px 0 0 124px;
    img {
      width: 698px;
      height: 625px;
    }
  }
}
.mb_md_3 {
  background: linear-gradient(180deg, #e9fdff 0%, #f8fcff 100%);
  .mb_cnt {
    background: url("../assets/image/mb_img_3_2.png") no-repeat 600px 20px;
    padding-bottom: 113px;
  }
  .mb_md_3_left {
    img {
      width: 775px;
      height: 512px;
    }
  }
}
.mb_md_3_right,
.mb_md_4_left {
  position: relative;
  text-align: right;
  p:nth-of-type(1) {
    font-size: 42px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 59px;
    padding-top: 218px;
    padding-bottom: 26px;
  }
  p:nth-of-type(2),
  p:nth-of-type(3) {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 32px;
  }
}
.mb_md_4 {
  .mb_cnt {
    justify-content: space-between;
  }
  .mb_md_4_left {
    min-width: 420px;
    text-align: left;
  }
  .mb_md_4_right {
    padding-top: 85px;
    text-align: right;
    position: relative;
    img:nth-of-type(1) {
      width: 700px;
      height: 453px;
    }
    img:nth-of-type(2) {
      position: absolute;
      top: 268px;
      right: 288px;
      width: 120px;
    }
    button {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      padding: 7px 10px;
      border: none;
      background: #24c366;
      border-radius: 15px;
      position: absolute;
      display: block;
      top: 258px;
      left: 239px;
    }
    button:nth-of-type(2) {
      background: #368fff;
      top: 258px;
      left: 406px;
    }
    button:nth-of-type(3) {
      background: #00baff;
      top: 396px;
      left: 321px;
    }
  }
}
.mb_md_5 {
  background: url("../assets/image/mb_img_5_2.png") no-repeat top right, #fbfbff;
  .mb_cnt {
    padding-top: 57px;
    position: relative;
  }
  .mb_md_3_right {
    padding-left: 149px;
    p:nth-of-type(1) {
      padding-top: 168px;
    }
  }
  .qr_btn {
    padding: 19px 12px 14px;
    background: #ffffff;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 17px;
    position: absolute;
    bottom: 80px;
    right: 0px;
    cursor: pointer;
    p {
      padding-top: 4px;
    }
  }
}
.mb_md_5_left {
  width: 673px;
  height: 512px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 910px;
  }
}
.dia_cnt {
  display: flex;
  justify-content: space-between;
  img {
    width: 150px;
    height: 150px;
  }
  p {
    color: #8c8c8c;
    text-align: center;
    padding-top: 8px;
  }
}
.el-dialog__wrapper {
  ::v-deep .el-dialog {
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    padding: 60px 60px 20px;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 22px;
    color: #8c8c8c;
  }
}
</style>
