<template>
  <div class="link_footer">
    <div class="ban">
      <p>
        版权所有：讯飞智元信息科技有限公司&nbsp;&nbsp;<a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
          >备案号：皖ICP备17025889号-4</a
        >&nbsp;&nbsp;<a
          href="http://www.beian.gov.cn/portal/registerSystemInfo"
          target="_blank"
          rel="noopener noreferrer"
          >皖公网安备34020702000587号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "link_footer",
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.link_footer {
  width: 100%;
  font-size: 13px;
}
.ban {
  text-align: center;
  height: 50px;
  background: #002153;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.8);
  a {
    color: rgba(255, 255, 255, 0.8);
    &:hover {
      color: #368fff;
    }
  }
}
</style>
